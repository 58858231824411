@import url("https://fonts.googleapis.com/css2?family=Dr+Sugiyama:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@300;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  background-color: #030014;
  @apply leading-[normal] m-0;
}
*,
::before,
::after {
  border-width: 0;
}
